import React from "react"
import Root from "../components/root"
import { graphql } from "gatsby"
import { ThemeProvider } from "../components/darkThemeContext"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import Helmet from "react-helmet"

const Index = ({ data, location }) => {
  return (
    <>
      <GatsbySeo
        title="Ricky Richards Photography"
        description="Ricky Richards Photography British Photo Journalist and Documentary photographer based in San Francisco USA"
        openGraph={{
          images: [
            {
              url: data.OGImage.sharingImage.resize.src,
              width: data.OGImage.sharingImage.resize.width,
              height: data.OGImage.sharingImage.resize.height,
              alt: data.OGImage.sharingImage.title,
            },
          ],
        }}
      />
      <Helmet>
        <meta
          name="google-site-verification"
          content="Ui4S-q4vDhVA0GRkbeBglx9NWBuLqEuAEnOmMimKCeI"
        />
      </Helmet>
      <ThemeProvider>
        <Root data={data} locationData={location} />
      </ThemeProvider>
    </>
  )
}

export default Index

export const data = graphql`
  query {
    LargeImage: allContentfulJournal(
      limit: 15
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      nodes {
        title
        id
        body {
          raw
        }
        image {
          description
          title
          file {
            details {
              image {
                height
                width
              }
            }
          }
          fluid(quality: 90) {
            srcSet
            src
            srcSetWebp
            base64
          }
        }
      }
    }
    OGImage: contentfulSharingImage {
      sharingImage {
        resize(width: 1000) {
          height
          src
          width
        }
      }
    }
  }
`
